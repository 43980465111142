import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppService, DbCallError } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { TimesheetService } from '../timesheet.service';
import { CheckTimesheetParams, CheckTimesheetResponse, HandleTimesheetsParams, Timesheet, TimesheetParsed } from '../../models/timesheet';
import { BehaviorSubject, Subject, debounceTime, map, of, switchMap, takeUntil } from 'rxjs';
import { UserService } from '../../user.service';
import { T7eService } from '../../t7e/t7e.service';
import * as moment from 'moment-timezone';
import { DocLink } from '../../models/contract';

@Component({
  selector: 'app-adjacent-timesheets',
  templateUrl: './adjacent-timesheets.component.html',
  styleUrls: ['./adjacent-timesheets.component.scss']
})
export class AdjacentTimesheetsComponent implements OnInit, OnDestroy {
  @Input() params$?: BehaviorSubject<HandleTimesheetsParams | null>
  @Output() overlapseChecked = new EventEmitter<CheckTs>()
  checkTsResult: CheckTs | null = null
  tsChecking$ = new BehaviorSubject<boolean>(true)
  private _destroy$ = new Subject<void>()

  constructor(
    private tsSvc: TimesheetService,
    private notifSvc: NotificationService,
    private appSvc: AppService,
    private userSvc: UserService,
    private t7e: T7eService,
  ) { 
    this.getIsAleadyInvoicedPeriod = this.getIsAleadyInvoicedPeriod.bind(this)
  }

  get debug() {return this.appSvc.debug }
  isMoreThanDeptHead = this.userSvc.isMoreThanDeptHead

  ngOnInit(): void {
    this.params$
      ?.pipe(
        takeUntil(this._destroy$),
      //debounceTime(1000)
    )
    .subscribe({
      next: params => {
        if (params) {
          this.checkTs(params)
        } else {
          this.tsChecking$.next(false)
          this.checkTsResult = null
        }

      }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  checkTs(params: CheckTimesheetParams): void {
    if (!params._startdate || !params._enddate || !params._conid) return
    this.tsChecking$.next(true)
    this.tsSvc.checkTs(params)
      .pipe(
        takeUntil(this._destroy$),
      // if response.lastinvoicedtsid is not null, then fetch the TS with that tsid
      // return the original response and the last TS
      switchMap(response => {
        if (response.lastinvoicedtsid) {
          return this.tsSvc.listTs({ _tsid: response.lastinvoicedtsid })
            .pipe(
              map(lastInvoicedTs => ({
                ...response,
                lastInvoicedTs,
                isAleadyInvoicedPeriod: this.getIsAleadyInvoicedPeriod(moment(params._startdate), lastInvoicedTs?.[0] || null),
                loadError: null,
              }))
            )
        }
        return of({ ...response, lastInvoicedTs: null, isAleadyInvoicedPeriod: false as false /*ts type incur-t átverni*/, loadError: null })
      })
      )
      .subscribe({
        next: response => {
          this.tsChecking$.next(false)
          console.log('check_ts válasz:', response)
          this.checkTsResult = response
          this.overlapseChecked.emit(this.checkTsResult)
        },
        error: err => {
          this.tsChecking$.next(false)
          console.error(err)
          this.checkTsResult = {
            ...this.emptyResponse,
            loadError: this.t7eNotifLoadError + JSON.stringify(err),
          }
          this.notifSvc.addObservableNotif({ msg: this.t7eNotifLoadError, class: 'warning', duration: 2000 })
        }
      })
  }

  private get emptyResponse(): CheckTs {
    return {
      loadError: null,
      overlaptsid: null,
      sametsid: null,
      next: { hours: null, startdate: null, tsid: null, },
      prev: { enddate: null, hours: null, tsid: null, },
      daytype: null,
      lastinvoiceddate: null,
      lastinvoicedtsid: null,
      isAleadyInvoicedPeriod: null,
      lastInvoicedTs: null,
    }
  }

  get lastTsText() {
    let num = -1
    let dimension: 'hours' | 'days'
    let isOverlap = false
    if (this.abs(this.checkTsResult?.prev?.hours) <= 36) {
      num = Math.round(this.abs(this.checkTsResult?.prev?.hours))
      dimension = 'hours'
    } else {
      num = Math.round(this.abs(this.checkTsResult?.prev?.hours) / 24)
      dimension = 'days'
    }
    if (this.checkTsResult?.prev?.hours! < 0) isOverlap = true
    if (isOverlap) {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'óra' : 'nap'} átfedés`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours' : 'days'} overlap`
      }
    } else {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'órája' : 'napja'}`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours ago' : 'days ago'}`
      }
    }
  }

  get nextTsText() {
    let num = -1
    let dimension: 'hours' | 'days'
    let isOverlap = false
    if (this.abs(this.checkTsResult?.next?.hours) <= 36) {
      num = Math.round(this.abs(this.checkTsResult?.next?.hours))
      dimension = 'hours'
    } else {
      num = Math.round(this.abs(this.checkTsResult?.next?.hours) / 24)
      dimension = 'days'
    }
    if (this.checkTsResult?.next?.hours! < 0) isOverlap = true
    if (isOverlap) {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'óra' : 'nap'} átfedés`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours' : 'days'} overlap`
      }
    } else {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'óra múlva' : 'nap múlva'}`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours from now' : 'days from now'}`
      }
    }
  }

  get isCritical(): boolean {
    if (!this.checkTsResult) return false
    if (this.checkTsResult?.loadError) return true
    if (this.checkTsResult.sametsid) return true
    if (this.checkTsResult.overlaptsid) return true
    if (this.checkTsResult.isAleadyInvoicedPeriod) return true
    
    return false
  }

  get isWarning(): boolean {
    if (!this.checkTsResult) return false
    if (this.checkTsResult?.daytype == 'holy') return true
    return false
  }

  get isOk(): boolean {
    return !this.isCritical && !this.isWarning
  }

  /**
   * Megnézi, hogy az ellenőrzött TS időszakát már kiszámlázták-e.
   * @param lastInvoicedTs Az utolsó kiszámlázott TS
   * @returns true, ha az ellenőrzött TS időszakát már kiszámlázták, egyébként false
   */
  private getIsAleadyInvoicedPeriod(tsDate: moment.Moment, lastInvoicedTs: TimesheetParsed | null): moment.Moment | false {
    if (!lastInvoicedTs) return false
    //const tsDate = moment(this.params$?.value?._startdate)
    const lastInvoicedDate = this.getEndOfInvPeriodOfLastTs(lastInvoicedTs)
    if (lastInvoicedDate && !tsDate.isAfter(lastInvoicedDate)) {
      return lastInvoicedDate
    }
    return false
  }

  /**
   * Visszaadja az utlsónak legenerált WTS végét. Ez a számlázási időszak vége.
   * @param lastInvoicedTs A user utolsó kiszámlázott TS-e
   * @returns Az utolsó kiszámlázott TS végdátuma, ha van TS és van generált WTS, egyébként null
   */
  private getEndOfInvPeriodOfLastTs(lastInvoicedTs: TimesheetParsed | null): moment.Moment | null {
    if (!lastInvoicedTs) return null
    // find the latest wtsEndDate of the arrDoclink
    const lastGeneratedWts = lastInvoicedTs.arrDoclink?.reduce<DocLink | null>((acc, curr) => {
      if (curr.wtsEndDate) {
        const currDate = moment(curr.ts)
        if (!acc || currDate.isAfter(moment(acc.ts))) return curr
      }
      return acc
    }, null)
    // ez már 23:59:59-re van állítva
    const lastWtsEndDate = moment(lastGeneratedWts?.wtsEndDate)
    return lastWtsEndDate || null
  }
  
  abs(num?: number | null) { return num ? Math.abs(num) : 0 }


  /** T7E */
  t7eNotifLoadError = this.t7e.getTranslation('app-adjacent-timesheets', 'notif-msg', 'load-error', 'Nem lehetett ellenőrizni az időpont átfedéseket: ') 
  
}

export interface CheckTs extends CheckTimesheetResponse {
  loadError: string | null
  lastInvoicedTs: Timesheet | null
  /** Ha már leszámlázott időszakra próbál TS-t bevinni, akkor ez az érték az utolsó számlázási időszak vége */
  isAleadyInvoicedPeriod: moment.Moment | false | null
}

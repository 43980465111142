<ng-container *ngIf="params$ | async">
    <div class="icons" *ngIf="!(tsChecking$ | async); else tsCheckLoading">
        <div *ngIf="debug">
            <code>checkTsResult: {{ checkTsResult | json }}</code>
        </div>
        <div class="ok" *ngIf="isOk">
            <button type="button" mat-icon-button kendoPopoverAnchor [popover]="overlapseReason" showOn="click"
                class="success-text">
                <mat-icon>
                    check_circle_outline
                </mat-icon>
            </button>
        </div>
        <div class="error" *ngIf="isCritical">
            <button type="button" mat-icon-button kendoPopoverAnchor [popover]="overlapseReason" showOn="click">
                <mat-icon color="warn">
                    error
                </mat-icon>
            </button>
        </div>
        <div class="warning" *ngIf="isWarning">
            <button type="button" mat-icon-button kendoPopoverAnchor [popover]="overlapseReason" showOn="click">
                <mat-icon color="accent">
                    warning
                </mat-icon>
            </button>
        </div>
    </div>
</ng-container>
<ng-template #tsCheckLoading>
    <div *ngIf="tsChecking$ | async" class="loading">
        <mat-spinner [diameter]="20"></mat-spinner>
    </div>
</ng-template>

<kendo-popover #overlapseReason>
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-overlapsereason title" 
        *ngIf="checkTsResult?.overlaptsid || checkTsResult?.sametsid; else noOverlap"
        t7e="overlapping-ts-title">
            Időpont átfedés
        </div>
        <ng-template #noOverlap>
            <span t7e="no-overlap">Nincs időpont átfedés</span>
        </ng-template>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate="">
        <div class="prev-next-ts" *ngIf="!(tsChecking$ | async); else tsCheckLoading">
             <div class="bg-danger text-white py-2 px-05 check-ts-error" *ngIf="checkTsResult?.lastinvoiceddate">
                <span t7e="ts-period-is-invoiced">Ez az időszak már le lett számlázva</span>
             </div>
            <div class="bg-warning py-2 px-05 check-ts-error" *ngIf="checkTsResult?.daytype=='holy'">
                <span t7e="ts-is-holiday">Ez egy ünnepnap</span>
            </div>
            <div class="bg-warning py-2 px-05 check-ts-error" *ngIf="debug && checkTsResult?.loadError">
                {{ checkTsResult?.loadError }}
            </div>
            <div class="bg-danger text-white py-2 px-05 check-ts-error" *ngIf="checkTsResult?.sametsid">
                <span t7e="ts-already-exists">Ezt a Timesheetet már bevitted</span>
                <br>
                <a [routerLink]="['/','ts', checkTsResult?.sametsid]" *ngIf="isMoreThanDeptHead" t7e="view">
                    Megnéz
                </a>
            </div>
            <div class="bg-danger text-white py-2 px-05 check-ts-error" *ngIf="checkTsResult?.overlaptsid">
                <span t7e="ts-overlaps-body">Egy másik Timesheet összeér ezzel</span>
                <br>
                <a [routerLink]="['/', 'ts', checkTsResult?.overlaptsid]" *ngIf="isMoreThanDeptHead" t7e="view">
                    Megnéz
                </a>
            </div>
            <div class="bg-danger text-white py-2 px-05 prev-ts" *ngIf="checkTsResult?.prev?.tsid">
                <span t7e="your-last-ts">Utolsó Timesheeted:</span>
                <mat-chip [title]="checkTsResult?.prev?.enddate">
                    {{ lastTsText }}
                </mat-chip>
            </div>
            <div class="bg-info text-white py-2 px-05 next-ts" *ngIf="checkTsResult?.next?.tsid">
                <span t7e="your-next-ts">Következő Timesheeted:</span>
                <mat-chip [title]="checkTsResult?.next?.startdate">
                    {{ nextTsText }}
                </mat-chip>
            </div>
        </div>
    </ng-template>
</kendo-popover>